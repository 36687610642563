.fund-dashboard-container {
    display: flex;
  flex-direction: column;
  text-align: right;
  padding: 50px;
  margin-left: auto;
  }
  
  .fund-text {
    color: white;
    font-size: 60px;
    line-height: 1.2;
    margin: 5px 0;
  }
  
  .fund-button {
    background-color: green;
    color : white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 150px;
    margin-top: 10px;
    cursor: pointer;
}
  