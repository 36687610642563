.fund-dashboard-container {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 50px;
  margin-left: auto;
}

.fund-text {
  color: rgb(22, 22, 107);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
    Verdana, sans-serif;
  font-size: 60px;
  line-height: 1.2;
  margin: 5px 0;
}

.fund-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 150px;
  margin-top: 10px;
  cursor: pointer;
}

.gradient-text {
  position: relative;
  display: inline-block;
}

.gradient-text::before {
  content: "MILESTONE";
  position: relative;
  top: 0;
  left: 0;
  background: linear-gradient(139deg, #33a3cb, #27c408);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 30px;
  font-weight: 500;
  z-index: -1;
}
.score-text {
  color: #fff; /* Text color */
  background-color: #007bff; /* Background color */
  padding: 10px 20px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold text */
  font-size: 24px; /* Font size */
  text-align: center; /* Center text */
}

/* .milestone-line {
    position: relative;
    width: 80%;
    height: 5px;
    margin-left: 40px;
    background-color: #322e2e;
  }
  
  .mark {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #007bff;
    border-radius: 50%;
    top: -3px;
    transform: translateX(-50%);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .mark:hover {
    background-color: #2883eb;
  }
  
  .score-popup {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #080e13;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }
  
  .mark:hover .score-popup {
    opacity: 1;
    transition: opacity 0.3s;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #080e13;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    pointer-events: none;
  }
  
  .win-mark {
    background-color: #28a745;
  }
  
  .win-mark:hover {
    background-color: #1e7e34;
  } */
