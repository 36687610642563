.order-form {
    display: flex;
    flex-direction: column;
    /* Change to column to stack elements vertically */
    align-items: flex-start;
    /* Align items to the start of the column */
    margin-top: 20px;
  }
  
  .order-type,
  .order-amount,
  .order-button-container {
    margin-bottom: 20px;
    /* Add margin at the bottom to separate elements */
  }
  .order-amount{
    margin-top: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  select,
  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .order-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-weight: bold;
  }
  
  .order-button.buy {
    background-color: #3cb371;
    /* Green color for Buy button */
  }
  
  .order-button.sell {
    background-color: #ff6347;
    /* Red color for Sell button */
  }
  

  .order-table {
    flex: 1;
    padding: 10px 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #282525; /* Use a lighter border color */
    background-color: #efefef; /* Add a subtle background color */
  }

  th, td {
    border: 1px solid #e0e0e0;
    padding: 12px; /* Slightly larger padding for better spacing */
    text-align: left;
  }

  th {
    background-color: #f0f0f0; /* Light gray background for table header */
  }

  /* Hover effect for table rows */
  tbody tr:hover {
    background-color: #64bc4c; /* Light background on hover */
  }


  .order-type-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .order-type-toggle button {
    flex: 1;
    padding: 10px 20px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
    background-color: rgb(187, 187, 240);
    transition: background-color 0.3s ease;
  }
  
  /* .order-type-toggle button.buy {
    background-color: #3cb371;
  }
  
  .order-type-toggle button.sell {
    background-color: #ff6347;
  } */
  
  .order-type-toggle button.active {
    color: black;
    background-color: rgb(89, 89, 238);
  }
  
  /* .order-type-toggle button.active.buy {
    background-color: #2b865e;
  }
  
  .order-type-toggle button.active.sell {
    background-color: #d93f2b;
  } */